import { Box, Skeleton, SvgIcon, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { CopyIcon } from 'components/Icons/Copy';
import { HearstIcon } from 'components/Icons/HearstIcon';
import { colors } from 'shared/constants/theme';
import { useNotification } from 'shared/hooks/useNotification';
import { Currency } from 'shared/utils/enum/coinEnums';
import { formatNumberToLocale, formatNumber } from 'shared/utils/formatter';

export const WalletBalance = (props: any) => {
  const { wallet, coinAbb, currency } = props;
  const { showSnackbar } = useNotification();

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    showSnackbar('Address Copied', 'success');
  };

  return (
    <InfoCard
      info={`${coinAbb} Balance`}
      headerColor="white"
      infoIconColor="white"
      subtitle={`${coinAbb} Balance`}
      showDivider={false}
      showTime={false}
      fullHeight
      styles={{
        height: '100%',
        background: colors.lightGreen,
        border: 'unset',
        color: 'white',
        justifyContent: 'unset',
      }}
      title=""
      content={
        <Box
          sx={{
            background: colors.lightGreen,
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {wallet ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                columnGap: '20px',
              }}
            >
              <Typography
                sx={{ fontSize: '32px', fontWeight: '600', color: '#fff' }}
              >
                {`${formatNumberToLocale(wallet?.amountInCoin, currency === Currency.Kaspa ? 3 : 6)} ${coinAbb}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '600',
                  color: 'white',
                }}
              >
                {`≈ ${formatNumber(wallet?.amountInUsd)} USD`}
              </Typography>
              <Box
                sx={{
                  padding: '15px',
                  borderRadius: '10px',
                  marginTop: '20px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: '500',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '10px',
                    marginTop: '10px',
                  }}
                >
                  {wallet?.walletAddress}
                  <SvgIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleCopy(wallet?.walletAddress);
                    }}
                  >
                    <CopyIcon />
                  </SvgIcon>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Skeleton sx={{ marginRight: 40, height: 100, width: '100%' }} />
          )}
          <Box sx={{ marginRight: '40px' }}>
            <HearstIcon />
          </Box>
        </Box>
      }
    />
  );
};
