import { useCallback, useEffect, useState } from 'react';
import { columns } from './columns';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box } from '@mui/material';
import { DeleteModal } from 'components/Modals/DeleteModal';
import UsersService from 'shared/services/users.service';
import hardwareService from 'shared/services/hardware.service';
import { useNotification } from 'shared/hooks/useNotification';
import { AdminHardwareForm } from 'components/Forms/AdminHardwareForm';
import { RedesignedTable } from '../RedesignedTable';

export const HardwareOpportunitiesTable = ({
  setHardwareChange,
  hardwareChange,
}: any) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [supplierAccounts, setSupplaerAccounts] = useState([]);
  const [hardwareData, setHardwareData] = useState<any[] | null>(null);
  const [hardwareOpps, setHardwareOpps] = useState<any[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [delHardwareID, setDelHardwareID] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useNotification();

  const fetchAccount = async (name?: string) => {
    setHardwareOpps(null);
    try {
      const response = await hardwareService.getHardwares({
        pageNumber: currentPage,
        limit: rowsPerPage,
        name,
      });

      const { data, totalPages } = response;

      setHardwareOpps(data);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const fetchSuppliers = async () => {
    setSupplaerAccounts([]);
    try {
      const response = await UsersService.getAllSuppliers();

      const { users } = response;

      const hardwareSuppliers = users.filter(
        (supplier: any) =>
          supplier.role === 'HardwareSupplier' || supplier.isHardware === true,
      );

      setSupplaerAccounts(hardwareSuppliers);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const handleEdit = (hardware: any): void => {
    setShowEditModal(true);
    setHardwareData(hardware);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchAccount();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const handleDeleteHardware = useCallback(async () => {
    setIsLoading(true);
    try {
      if (delHardwareID) {
        await hardwareService.deleteHardware(delHardwareID);
        fetchAccount();
        setDelHardwareID('');
        showSnackbar('Successfully Deleted', 'success');
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [delHardwareID]);

  return (
    <Box marginY="30px">
      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="750px"
        backdropOpen={isLoading}
      >
        <AdminHardwareForm
          setShowModal={setShowModal}
          onFinish={fetchAccount}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
          setHardwareChange={setHardwareChange}
          hardwareChange={hardwareChange}
        />
      </CustomModal>
      <RedesignedTable
        tableName="Hardware devices"
        items={hardwareOpps}
        columns={columns}
        setShowModal={setShowModal}
        handleEdit={handleEdit}
        showEnd={true}
        setCurrentPage={setCurrentPage}
        onRowDelete={setDelHardwareID}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        addButtonText="Add device"
        searchable={true}
        fetchData={fetchAccount}
      />
      <CustomModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        maxWidth="750px"
        backdropOpen={isLoading}
      >
        <AdminHardwareForm
          setShowModal={setShowEditModal}
          editData={hardwareData}
          onFinish={fetchAccount}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
        />
      </CustomModal>
      <CustomModal open={!!delHardwareID} onClose={() => setDelHardwareID('')}>
        <Box className="container">
          <DeleteModal
            title="Hardware"
            onClick={handleDeleteHardware}
            onCancel={() => setDelHardwareID('')}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};
