import { useState, useEffect, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import UsersService from 'shared/services/users.service';
import HostingService from 'shared/services/hosting.service';
import HardwareService from 'shared/services/hardware.service';
import hardwareOpportunityService from 'shared/services/hardware-opportunity.service';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ControlledAutocomplete } from 'components/ControlledInputs/Autocomplete';
import { container, content, formTitle } from '../styles';
import { colors } from 'shared/constants/theme';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import { currencies } from 'shared/utils/currency';

const availability = [
  { label: '1 month', value: 1 },
  { label: '2 month', value: 2 },
  { label: '3 month', value: 3 },
  { label: 'more than 3 months', value: 4 },
];

export const AdminHardwareSupplierDetailsForm = ({
  setShowModal,
  editData,
  onFinish,
  setIsLoading,
  setHardwareChange,
  hardwareChange,
  hostingOpportunity,
}: any): JSX.Element => {
  const [isSupplierError, setIsSupplierError] = useState(false);
  const [isDeviceError, setIsDeviceError] = useState(false);
  const [isAvailabilityError, setIsAvailabilityError] = useState(false);
  const [supplaerAccounts, setSupplaerAccounts] = useState<any[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<any>(
    editData ? editData.hardware : null,
  );
  const [devices, setDevices] = useState<any[]>([]);
  const [hostingOpportunities, setHostingOpportunities] = useState<any[]>([]);
  const [selectedAvailability, setSelectedAvailability] = useState<any>(
    editData?.waitingMonths
      ? availability.find((el) => el.value === editData.waitingMonths)
      : null,
  );
  const [selectedSupplier, setSelectedSupplier] = useState<any>(
    editData?.supplier ? editData.supplier : null,
  );
  const [selectedHostingOpportunity, setSelectedHostingOpportunity] =
    useState<any>(hostingOpportunity);
  const { showSnackbar } = useNotification();
  const defaultCurrency =
    editData && editData?.hardware ? editData?.hardware.currency : null;

  const fetchSuppliers = async () => {
    setSupplaerAccounts([]);
    try {
      const response = await UsersService.getAllSuppliers();

      const { users } = response;

      const hardwareSuppliers = users.filter(
        (supplier: any) =>
          supplier.role === 'HardwereSupplier' || supplier.isHardware === true,
      );

      setSupplaerAccounts(hardwareSuppliers);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const fetchDevices = async () => {
    setDevices([]);
    try {
      const { hardwares } = await HardwareService.getAllHardwares();

      setDevices(hardwares);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const fetchHostingOpportunities = async () => {
    setHostingOpportunities([]);
    try {
      const response = await HostingService.getAllHostings();

      const { opportunities } = response;

      setHostingOpportunities(opportunities);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    if (selectedSupplier?.id) {
      setIsSupplierError(false);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    fetchSuppliers();
    fetchDevices();
    fetchHostingOpportunities();
  }, []);

  const schema = yup.object().shape({
    price: yup.number().required('Device price is a required field'),
    delivery: yup.number().required('Delivery cost is a required field'),
    overclocking: yup.number().required('Margin is a required field'),
    container: yup.number().required('Custom cost is a required field'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const currency = watch('currency');

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => device.currency === currency);
  }, [devices, currency]);

  useEffect(() => {
    setSelectedDevice(null);
  }, [currency]);

  const handleClick = (event: any) => {
    if (!selectedSupplier?.username) {
      event.preventDefault();
      setIsSupplierError(true);

      return;
    }
  };

  const onSubmit: any = async (hardwareData: any): Promise<void> => {
    setIsLoading(true);
    try {
      const { currency, ...filteredHardwareData } = hardwareData;

      const payload = {
        ...filteredHardwareData,
        supplier: selectedSupplier?.id,
        hostingOpportunity: selectedHostingOpportunity?.id,
        hardware: selectedDevice?.id,
        waitingMonths: selectedAvailability?.value,
      };

      if (editData) {
        await hardwareOpportunityService.updateHardwareOpportunity(
          editData.id,
          {
            ...filteredHardwareData,
            supplier: selectedSupplier?.id,
            hostingOpportunity: selectedHostingOpportunity?.id,
            image: payload.image ? payload.image : editData.image,
            waitingMonths: selectedAvailability?.value,
            hardware: selectedDevice?.id,
          },
        );
        setHardwareChange(!hardwareChange);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await hardwareOpportunityService.createHardwareOpportunity(payload);
        setHardwareChange(!hardwareChange);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  return (
    <Box sx={container}>
      <form style={content} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={formTitle}>
          {editData ? 'Edit' : 'Create'} hardware opportunity
        </Typography>
        <ControlledAutocomplete
          label="Hoster name"
          optionLabel="name"
          name="hostingOpportunity"
          options={hostingOpportunities}
          value={selectedHostingOpportunity}
          setValue={setSelectedHostingOpportunity}
          control={control}
          width="100%"
          disabled={true}
        />
        <Box sx={{ width: '100%' }}>
          <ControlledSelect
            control={control}
            name="currency"
            defaultValue={defaultCurrency}
            placeholder="Choose coin"
            label="Cryptocurrency"
            error={errors}
            options={currencies}
          />
        </Box>
        <Box sx={{ marginBottom: '10px', width: '100%' }}>
          <ControlledAutocomplete
            label="Device"
            initialLabel="Choose device"
            optionLabel="device"
            name="device"
            options={filteredDevices}
            value={selectedDevice}
            setValue={setSelectedDevice}
            control={control}
            width="100%"
            error={isDeviceError}
            errorText="Hosting opportunity is a required field"
            disabled={!currency}
          />
        </Box>
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            fontSize: '20px',
            fontWeight: '500',
          }}
        >
          Supplier details
        </Typography>
        <Box width="100%" display="flex" flexDirection="column" rowGap="20px">
          <ControlledAutocomplete
            label="Supplier name"
            initialLabel="Choose supplier"
            optionLabel="username"
            name="supplier"
            options={supplaerAccounts}
            value={selectedSupplier}
            setValue={setSelectedSupplier}
            control={control}
            width="100%"
            error={isSupplierError}
            errorText="Supplier is a required field"
          />
          <Box display="flex" columnGap="20px">
            <ControlledTextField
              control={control}
              defaultValue={editData?.price ?? ''}
              name="price"
              label="Device price, $"
              error={errors}
              width="50%"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.delivery ?? ''}
              name="delivery"
              label="Delivery cost, $"
              error={errors}
              width="50%"
            />
          </Box>
          <Box display="flex" columnGap="20px">
            <ControlledTextField
              control={control}
              defaultValue={editData?.overclocking ?? ''}
              name="overclocking"
              label="Margin, $"
              error={errors}
              width="50%"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.container ?? ''}
              name="container"
              label="Custom cost, $"
              error={errors}
              width="50%"
            />
          </Box>
          <ControlledAutocomplete
            label="Availability"
            initialLabel="Specify availability"
            optionLabel="label"
            name="availability"
            options={availability}
            value={selectedAvailability}
            setValue={setSelectedAvailability}
            control={control}
            width="100%"
            error={isAvailabilityError}
            errorText="Availability is a required field"
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            sx={{
              marginRight: '10px',
              background: '#eeeeee',
              color: 'black',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#eeeeee',
              },
            }}
            onClick={() => setShowModal(false)}
            type="button"
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: colors.lightGreen,
              color: 'white',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#1d4fbb',
              },
            }}
            type="submit"
            onClick={(event) => handleClick(event)}
          >
            {editData ? 'Save' : 'Create'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
