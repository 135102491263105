import { Box } from '@mui/material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import moment from 'moment';
import { DownloadButton } from '../AdminSimulationTable/cells';
import { downloadFile } from 'shared/utils/fileDownloader';
import { formatNumberToLocale } from 'shared/utils/formatter';

export const columns: ColumnsType[] = [
  {
    id: 'date',
    label: 'Month',
    minWidth: 100,
    align: 'center',
    render: ({ date }: any) => (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {date}
      </Box>
    ),
  },
  {
    id: 'electricityCost',
    label: 'Estimate total, $',
    minWidth: 100,
    align: 'center',
    render: ({ electricityCost }: any) => (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        ${formatNumberToLocale(electricityCost, 4)}
      </Box>
    ),
  },
  {
    label: 'Download report',
    sortable: false,
    minWidth: 150,
    align: 'center',
    render: ({ report }: any) => (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <DownloadButton
          buttonText="Download"
          onClick={() => {
            downloadFile(report);
          }}
        />
      </Box>
    ),
  },
];
