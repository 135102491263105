import { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { StatisticsCard } from 'components/Cards/StatisticsCard';
import { LiveUtcDateTime } from 'components/LiveUtcDateTime';
import { threeRowGridItem } from 'pages/Customer/Operation/styles';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { Currency } from 'shared/utils/enum/coinEnums';

const createBox = (title: any, value: any, currency: any) => ({
  title,
  value,
  currency,
});

const getCurrencyData = (
  totalMinedCoins: any,
  totalHashrate: any,
  name: any,
  measurementUnit: string,
) => ({
  mined: createBox(`Past 30 days mined ${name}`, totalMinedCoins, name),
  hashrate: createBox(
    `Total Hashrate ${name}`,
    totalHashrate.toFixed(2),
    measurementUnit,
  ),
});

export const Operations = ({ currencyStatistics }: any) => {
  const currencyBoxesValues = useMemo(() => {
    if (!currencyStatistics) return null;

    if (currencyStatistics.currencies.length === 0) {
      return {
        firstBox: createBox('-', '-', ''),
        secondBox: createBox('-', '-', ''),
        thirdBox: createBox('-', '-', ''),
      };
    }

    const firstBox = {
      title: 'Total Assets',
      value: currencyStatistics
        ? `$${formatNumberToLocale(currencyStatistics.totalAssets, 2)}`
        : null,
      currency: 'USD',
    };

    const bitcoin = currencyStatistics.currencies.find(
      (i: any) => i.currency === Currency.Bitcoin,
    );
    const kaspa = currencyStatistics.currencies.find(
      (i: any) => i.currency === Currency.Kaspa,
    );

    const alephium = currencyStatistics.currencies.find(
      (i: any) => i.currency === Currency.Alephium,
    );

    const litecoin = currencyStatistics.currencies.find(
      (i: any) => i.currency === Currency.Litecoin,
    );

    const bitcoinData = bitcoin
      ? getCurrencyData(
          formatNumberToLocale(bitcoin.totalMinedCoins, 6),
          bitcoin.totalHashrate,
          'BTC',
          bitcoin.measurementUnit,
        )
      : null;
    const kaspaData = kaspa
      ? getCurrencyData(
          formatNumberToLocale(kaspa.totalMinedCoins, 3),
          kaspa.totalHashrate,
          'KAS',
          kaspa.measurementUnit,
        )
      : null;

    const alephiumData = alephium
      ? getCurrencyData(
          formatNumberToLocale(alephium.totalMinedCoins, 3),
          alephium.totalHashrate,
          'ALPH',
          alephium.measurementUnit,
        )
      : null;

    const litecoinData = litecoin
      ? getCurrencyData(
          formatNumberToLocale(litecoin.totalMinedCoins, 3),
          litecoin.totalHashrate,
          'LTC',
          litecoin.measurementUnit,
        )
      : null;

    if (bitcoinData && kaspaData) {
      return {
        firstBox,
        secondBox: bitcoinData.mined,
        thirdBox: kaspaData.mined,
      };
    }

    if (bitcoinData) {
      return {
        firstBox,
        secondBox: bitcoinData.mined,
        thirdBox: bitcoinData.hashrate,
      };
    }

    if (kaspaData) {
      return {
        firstBox,
        secondBox: kaspaData.mined,
        thirdBox: kaspaData.hashrate,
      };
    }
    if (alephiumData) {
      return {
        firstBox,
        secondBox: alephiumData.mined,
        thirdBox: alephiumData.hashrate,
      };
    }

    if (litecoinData) {
      return {
        firstBox,
        secondBox: litecoinData.mined,
        thirdBox: litecoinData.hashrate,
      };
    }
  }, [currencyStatistics]);

  const statisticsData = useMemo(
    () => [
      currencyBoxesValues?.firstBox,
      currencyBoxesValues?.secondBox,
      currencyBoxesValues?.thirdBox,
    ],
    [currencyBoxesValues],
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SectionHeading
          sx={{
            color: '#fff',
          }}
          name="Your operations"
        />
        <LiveUtcDateTime />
      </Box>
      <Grid
        marginTop={0.5}
        marginBottom={5}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        {statisticsData.map((statistic: any) => (
          <Grid {...threeRowGridItem}>
            <StatisticsCard
              title={statistic ? statistic.title : null}
              value={statistic ? statistic.value : null}
              currency={statistic ? statistic.currency : null}
              small
              colored
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
