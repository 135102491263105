import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ControlledAutocomplete } from 'components/ControlledInputs/Autocomplete';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { SvgComponentHide } from 'components/Icons/Hide';
import { SvgComponentSee } from 'components/Icons/See';
import { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNotification } from 'shared/hooks/useNotification';
import UsersService from 'shared/services/users.service';
import { UserRole } from 'shared/utils/enum/UserEnum';
import * as yup from 'yup';
import { IFormInput, IProps } from '../types';
import {
  buttons,
  container,
  content,
  formTitle,
  submitButton,
} from 'components/Forms/styles';

export const AffiliateForm: FC<IProps> = ({
  setShowModal,
  editData,
  onFinish,
}): JSX.Element => {
  const { showSnackbar } = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [customers, setCustomers] = useState<Array<any>>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<any[]>(
    editData?.affiliateCustomers.map(({ id, email }: any) => ({
      value: id,
      email,
    })) ?? [],
  );

  const getCustomers = async () => {
    try {
      const { users } = await UsersService.getCustomersWithoutAffiliate();
      return setCustomers(
        users.map((s: any) => ({ value: s.id, email: s.email })),
      );
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const schema = yup.object().shape({
    email: yup.string().required('Email is a required field').email().trim(),
    username: yup.string().required('Username is a required field'),
    password: editData?.id
      ? yup.string()
      : yup
          .string()
          .required('Password is a required field')
          .min(8, 'Password must be at least 8 characters'),
    companyName: yup.string().required('Company name is a required field'),
    walletAddress: yup.string().notRequired(),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formValues = useMemo(
    () =>
      editData
        ? {
            companyName: editData.companyName,
            email: editData.email,
            password: editData.password,
            username: editData.username,
            walletAddress: editData.walletAddress,
          }
        : {
            companyName: '',
            email: '',
            password: '',
            username: '',
            walletAddress: '',
          },
    [editData],
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (
    userData,
  ): Promise<void> => {
    const affiliateCustomers = selectedCustomers.map((item) => item.value);
    const data = {
      ...userData,
      affiliateCustomers,
      role: UserRole.AFFILIATE,
    };

    try {
      if (editData) {
        await UsersService.updateUser(editData.id, data);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await UsersService.createUser(data);
        showSnackbar('Successfully Created', 'success');
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      onFinish();
      setShowModal(false);
    }
  };

  return (
    <>
      <Box sx={container}>
        <form style={content} onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={formTitle}>
            {editData ? 'Edit' : 'Add'} Affiliate
          </Typography>
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.username ?? ''}
            name="username"
            label="Username"
            error={errors}
            placeholder="Username"
            disableAutocomplete
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.email ?? ''}
            name="email"
            label="Email"
            error={errors}
            placeholder="name.example@gmail.com"
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.companyName ?? ''}
            name="companyName"
            label="Company name"
            error={errors}
            placeholder="Company name"
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.walletAddress ?? ''}
            name="walletAddress"
            label="Bitcoin (BTC) wallet address"
            error={errors}
            placeholder="Bitcoin (BTC) wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.bitcoinCashWalletAddress ?? ''}
            name="bitcoinCashWalletAddress"
            label="Bitcoin Cash (BCH) wallet address"
            error={errors}
            placeholder="Bitcoin Cash (BCH) wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.kaspaWalletAddress ?? ''}
            name="kaspaWalletAddress"
            label="Kaspa (KAS) wallet address"
            error={errors}
            placeholder="Kaspa (KAS) wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.litecoinWalletAddress ?? ''}
            name="litecoinWalletAddress"
            label="Litecoin (LTC) / Dogecoin (DOGE) wallet address"
            error={errors}
            placeholder="Litecoin (LTC) / Dogecoin (DOGE) wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.ethWalletAddress ?? ''}
            name="ethWalletAddress"
            label="Ethereum wallet address"
            error={errors}
            placeholder="Ethereum wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.alephiumWalletAddress ?? ''}
            name="alephiumWalletAddress"
            label="Alephium (ALPH) wallet address"
            error={errors}
            placeholder="Alephium (ALPH) wallet address"
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.affiliatePercent ?? ''}
            name="affiliatePercent"
            label="Affiliate percent"
            error={errors}
          />
          {selectedCustomers.length ? (
            <ControlledAutocomplete
              label="Related customers"
              initialLabel=""
              optionLabel="email"
              name="affiliateCustomers"
              options={customers}
              value={selectedCustomers}
              setValue={setSelectedCustomers}
              control={control}
              width="100%"
              multiple={true}
              disabled={true}
            />
          ) : (
            <></>
          )}
          {!editData?.id && (
            <ControlledTextField
              control={control}
              defaultValue=""
              name="password"
              label="Password"
              placeholder="Password"
              error={errors}
              required
              disableAutocomplete
              type={showPassword ? 'text' : 'password'}
              textFieldProps={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <SvgComponentSee />
                        ) : (
                          <SvgComponentHide />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          <Box sx={buttons}>
            <Button sx={submitButton} type="submit">
              {editData ? 'Save Changes' : 'Add User'}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};
