import { handleCoinAbb } from 'shared/utils/currency';
import { getColumns } from './columns';
import { RedesignedTable } from 'components/Tables/RedesignedTable';

export const TransactionsTable = (props: any) => {
  const { transactions, currency } = props;

  const coinAbb = handleCoinAbb(currency);

  return (
    <RedesignedTable
      paginated={false}
      items={transactions}
      columns={getColumns(coinAbb)}
    />
  );
};
