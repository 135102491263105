import { ColumnsType } from 'components/Tables/BaseTable/types';
import { formatNumberToLocale } from 'shared/utils/formatter';

export const getColumns = (coinAbb: string): ColumnsType[] => {
  return [
    {
      label: 'Earnings Date',
      minWidth: 100,
      align: 'left',
      sortable: false,
      render: ({ timestamp }) => `${timestamp.toLocaleString().slice(0, 10)}`,
    },
    {
      id: 'hashrate',
      label: 'Daily Hashrate',
      minWidth: 100,
      align: 'left',
      sortable: false,
    },
    {
      id: 'earnings',
      label: `Earnings(${coinAbb})`,
      minWidth: 100,
      align: 'left',
      sortable: false,
      render: ({ earnings }) =>
        `${formatNumberToLocale(earnings, coinAbb === 'KAS' ? 3 : 6)}`,
    },
  ];
};
